import { Component, EventEmitter, forwardRef, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UniversalFormInputComponent } from '../universal-form-input/universal-form-input.component';
import { ControlInfo, UniversalFormService } from 'src/app/services/universal-form/universal-form.service';
import { Layout, LayoutItem } from './types/universal-form-layout.type';

@Component({
  selector: 'app-universal-form-layout',
  templateUrl: './universal-form-layout.component.html',
  styleUrls: ['./universal-form-layout.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UniversalFormInputComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UniversalFormLayoutComponent),
      multi: true,
    },
  ],
})
export class UniversalFormLayoutComponent implements OnInit {
  @Input({ required: true }) formGroup: FormGroup | undefined;
  @Input({ required: true }) resourceName!: string;
  @Input({ required: true }) layoutName: string = '';
  @Input() parentPath: string = '';

  @Output() tabChange = new EventEmitter<string>();

  private universalFormService = inject(UniversalFormService);
  controlMap: Map<string, ControlInfo> = new Map();

  layout!: Layout;
  activeTab: string = '';
  currentTabContent: LayoutItem[] = [];

  ngOnInit(): void {
    if (this.formGroup) {
      this.controlMap = this.universalFormService.getControlMap(
        this.resourceName,
        this.formGroup,
        this.parentPath ? this.parentPath : undefined,
      );
    }
    this.loadJsonFile(this.layoutName).then((data) => {
      this.initLayout(data as Layout);
    });
  }

  private initLayout(data: Layout): void {
    this.layout = data;
    if (this.layout.tabs.length > 0) {
      this.setActiveTab(this.layout.tabs[0].name);
    }
  }

  setActiveTab(tabName: string): void {
    this.activeTab = tabName;
    this.tabChange.emit(tabName);
    this.updateTabContent();
  }

  private updateTabContent(): void {
    this.currentTabContent = this.layout.tabs.find((tab) => tab.name === this.activeTab)?.items || [];
  }

  private async loadJsonFile(filename: string): Promise<any> {
    try {
      const response = await fetch(`assets/forms/${filename}.json`);
      if (!response.ok) {
        throw new Error(`Failed to load JSON file: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error loading JSON file:', error);
      return null;
    }
  }
}
