<div class="mb-3">
  <app-general-search
    [univers_list]="univers_list"
    [show_univers]="false"
    (searchAction)="search($event)"
  ></app-general-search>
</div>

<app-loading [load]="loading" [placeholder]="placeholder">
  @if (dossiers.length) {
    <app-list-dossier-item [dossiers]="dossiers"></app-list-dossier-item>
    @if (pagination) {
      <app-paginate [pagination]="pagination" (loadPage)="loadPage($event)"></app-paginate>
    }
  } @else {
    <app-no-result
      title="Pas de dossier pour le moment !"
      description="Vous verrez apparaître ici la liste des dossiers vous concernant dans votre zone de compétence"
    ></app-no-result>
  }
</app-loading>
<ng-template #placeholder>
  <div class="d-flex flex-column gap-2">
    <app-recap-dossier [placeholder]="10"></app-recap-dossier>
  </div>
</ng-template>
