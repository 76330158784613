import { AfterViewInit, Component, inject, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  ControlInfo,
  ControlType,
  UniversalFormService,
} from '../../../services/universal-form/universal-form.service';
import { JsonSchema } from 'src/app/services/universal-form/universal-form.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UniversalFormCheckComponent } from '../universal-form-check/universal-form-check.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { LayoutItem } from '../universal-form-layout/types/universal-form-layout.type';

@Component({
  selector: 'app-universal-form-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, UniversalFormCheckComponent],
  templateUrl: './universal-form-input.component.html',
  styleUrl: './universal-form-input.component.scss',
  animations: [
    trigger('itemAnimation', [
      transition(':enter', [
        style({
          transform: 'scale(0.3)',
          opacity: 0,
          height: 0,
          padding: 0,
          margin: 0,
        }),
        animate(
          '200ms ease-out',
          style({
            transform: 'scale(1)',
            opacity: 1,
            height: '*',
            padding: '*',
            margin: '*',
          }),
        ),
      ]),
      transition(':leave', [
        style({
          transform: 'scale(1)',
          opacity: 1,
          height: '*',
          padding: '*',
          margin: '*',
        }),
        animate(
          '200ms ease-in',
          style({
            transform: 'scale(0.3)',
            opacity: 0,
            height: 0,
            padding: 0,
            margin: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class UniversalFormInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() field: ControlInfo | undefined;
  @Input() formGroupInstance: FormGroup | undefined;
  @Input() parentPath: string = '';
  @Input() index: number | undefined;
  @Input() filledMarker: boolean = false;
  @Input() showDescription: boolean = false;

  value: ControlInfo | undefined;
  ControlType = ControlType;
  private universalFormService = inject(UniversalFormService);
  private sanitizer = inject(DomSanitizer);
  UniversalFormInputComponent = UniversalFormInputComponent;
  objects: any;
  currentKeyValueFormGroup: FormGroup | undefined;
  onChange: any = () => {};
  onTouch: any = () => {};

  async ngOnInit(): Promise<void> {
    this.objects = await this.loadObjectsJsonFile();
  }

  ngAfterViewInit(): void {
    if (
      this.field?.type === ControlType.checkbox &&
      this.field?.control &&
      !this.field?.control.value &&
      this.isRequired(this.field?.control)
    ) {
      this.field?.control.setValue(false);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  isRequired(control: unknown): boolean {
    if (control instanceof AbstractControl) {
      if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        return validator && validator['required'];
      }
      return false;
    }
    return false;
  }

  processFieldDescription(text: string): SafeHtml {
    if (!text) return '';

    // Replace URLs with anchor tags
    const urlRegex = /(http:\/\/[^\s]+|https:\/\/[^\s]+)/g;
    let htmlText = text.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

    // Split on semicolons and wrap each line in paragraph tags
    const lines = htmlText
      .split(';')
      .map((line) => line.trim())
      .filter((line) => line);
    htmlText = lines.map((line) => `<p>${line}</p>`).join('');

    return this.sanitizer.bypassSecurityTrustHtml(htmlText);
  }

  getError(control: AbstractControl): string {
    if (control.errors) {
      const firstErrorKey = Object.keys(control.errors)[0];
      switch (firstErrorKey) {
        case 'required':
          return 'Ce champ est requis';
        case 'min':
          return 'La valeur est trop petite : minimum ' + control.errors!['min'].min;
        case 'max':
          return 'La valeur est trop grande : maximum ' + control.errors!['max'].max;
        default:
          return "La valeur du champ n'est pas valide";
      }
    }
    return '';
  }

  getPrettyText(text: string): string {
    return this.universalFormService.getPrettyText(text);
  }

  getPrettyValue(text: unknown): string {
    let prettyText = '';
    switch (typeof text) {
      case 'number':
        prettyText = this.universalFormService.getPrettyValue(text.toString());
        break;
      case 'boolean':
        prettyText = text ? 'Oui' : 'Non';
        break;
      default:
        prettyText = this.universalFormService.getPrettyValue(text as string);
        break;
    }
    return prettyText;
  }

  getEnumValues(enumObj: any): string[] {
    return Object.keys(enumObj)
      .filter((key) => isNaN(Number(key)))
      .map((key) => enumObj[key]);
  }

  addItem(): void {
    const control = this.field?.control as unknown as FormArray;

    if (this.field?.type === ControlType.array && (this.field?.items?.type === 'string' || this.field?.options)) {
      const subControl = this.universalFormService.createControlInfoFormControl(this.field);
      control.push(subControl);
    } else {
      const formGroup = this.universalFormService.createItemFormGroup(this.field!);
      control.push(formGroup);
      const ctrlInfo = this.getControlInfo(this.field!.itemsControlInfo, this.field!.key, formGroup);
      if (ctrlInfo) {
        this.field!.childs.push(ctrlInfo);
      }
    }
  }

  addObject(): void {
    if (this.field?.fullKey && this.field?.key !== this.field?.fullKey) {
      const path = this.field.fullKey.split('.');
      const parentGroup = this.getParentFormGroup(path);
      if (parentGroup) {
        const newControl = this.universalFormService.createItemFormGroup(this.field);
        parentGroup.removeControl(path[path.length - 1]);
        parentGroup.addControl(path[path.length - 1], newControl);
        parentGroup.updateValueAndValidity();
      }
    } else {
      this.field!.control = this.universalFormService.createItemFormGroup(this.field!);
      // Handle parentPath
      const key = this.parentPath ? this.field!.key.replace(this.parentPath + '.', '') : this.field!.key;
      this.formGroupInstance?.removeControl(key);
      this.formGroupInstance?.addControl(key, this.field!.control);
      this.formGroupInstance?.updateValueAndValidity();
    }
  }

  getParentFormGroup(path: string[]): FormGroup | null {
    let current: AbstractControl | null = this.formGroupInstance || null;
    const offset = this.parentPath ? this.parentPath.split('.').length : 0;

    for (let i = offset; i < path.length - 1; i++) {
      if (!current) return null;
      // Si c'est un FormArray, on doit extraire l'index
      if (current instanceof FormArray) {
        const arrayIndex = parseInt(path[i]);
        if (isNaN(arrayIndex)) return null;
        current = current.at(arrayIndex);
      } else {
        current = current.get(path[i]);
      }
      // Vérifier que le contrôle obtenu est bien un FormGroup ou FormArray
      if (!(current instanceof FormGroup) && !(current instanceof FormArray)) {
        return null;
      }

      if (current instanceof FormArray && this.index !== undefined) {
        current = current.at(this.index);
      }
    }

    return current instanceof FormGroup ? current : null;
  }

  addKeyValue(): void {
    this.currentKeyValueFormGroup = new FormGroup({
      key: new FormControl(''),
      value: new FormControl(''),
    });
  }

  removeKeyValue(): void {
    this.currentKeyValueFormGroup = undefined;
  }

  saveKeyValue(): void {
    (this.field?.control as FormGroup).addControl(
      this.currentKeyValueFormGroup?.value.key,
      new FormControl(this.currentKeyValueFormGroup?.value.value),
    );
    this.removeKeyValue();
  }

  removeItem(control: FormArray, index: number): void {
    control.removeAt(index);
  }

  removeObject(): void {
    if (this.field?.fullKey && this.field?.key !== this.field?.fullKey) {
      const path = this.field.fullKey.split('.');
      const parentGroup = this.getParentFormGroup(path);

      if (parentGroup) {
        const lastKey = path[path.length - 1];
        parentGroup.removeControl(lastKey);
        this.field!.control = new FormGroup({});
        parentGroup.addControl(lastKey, this.field!.control);
        parentGroup.updateValueAndValidity();
      }
    } else {
      // Fallback pour le comportement original
      this.field!.control = new FormGroup({});
      // Handle parentPath
      const key = this.parentPath ? this.field!.key.replace(this.parentPath + '.', '') : this.field!.key;
      this.formGroupInstance?.removeControl(key);
      this.formGroupInstance?.updateValueAndValidity();
    }
  }

  isFormArray(control: AbstractControl): control is FormArray {
    return control instanceof FormArray;
  }

  isFormGroup(control: AbstractControl): control is FormGroup {
    return control instanceof FormGroup;
  }

  getFormGroup(control: JsonSchema | undefined): FormGroup {
    if (control) {
      return this.universalFormService.jsonSchemaToFormGroup('', control, '');
    }
    return new FormGroup({});
  }

  hasValue(control: AbstractControl | null): boolean {
    if (!control) {
      return false;
    }

    if (control instanceof FormGroup) {
      return Object.keys(control.controls).length > 0;
    }

    if (control instanceof FormArray) {
      return control.length > 0;
    }

    const value = control.value;
    if (value === null || value === undefined) {
      return false;
    }

    if (typeof value === 'string') {
      return value.trim().length > 0;
    }

    return true;
  }

  getControlInfo(
    controlInfo: { [key: string]: ControlInfo },
    key: string,
    control: AbstractControl,
  ): ControlInfo | undefined {
    if (controlInfo[key]) {
      const ctrl: ControlInfo = { ...controlInfo[key] };
      // On ne gère pas les array ici ...
      if (ctrl.type === ControlType.array) {
        ctrl.control = control as FormArray;
      } else {
        ctrl.control = control as FormControl;
      }
      return ctrl;
    } else {
      return undefined;
    }
  }

  getControlsLength(controls: object): number {
    if (!controls) {
      return 0;
    }
    return Object.keys(controls).length;
  }

  getLayout(key: string): LayoutItem[] {
    const lastKey = key.split('.').pop() || '';
    return this.objects[lastKey] || [];
  }

  private async loadObjectsJsonFile(): Promise<any> {
    try {
      const response = await fetch(`assets/forms/objects/objects.json`);
      if (!response.ok) {
        throw new Error(`Failed to load JSON file: ${response.statusText}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error loading JSON file:', error);
      return null;
    }
  }
}
