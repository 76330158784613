@if (layout) {
  <div>
    <!-- <h1>{{ layout.title }}</h1>
    <p>{{ layout.description }}</p> -->

    <div class="tabs">
      @for (tab of layout.tabs; track tab.name) {
        <div 
          class="tab" 
          [class.active]="activeTab === tab.name"
          [class.ff-medium]="activeTab === tab.name"
          (click)="setActiveTab(tab.name)"
        >
          {{ tab.name }}
        </div>
      }
    </div>

    <div class="tab-content frosted-glass">
      <div class="row">
      @for (item of currentTabContent; track item.name) {
        @switch (item.type) {
          @case ('title') {
            <h2>{{ item.name }}</h2>
          }
          @case ('separator') {
            <hr>
          }
          @case ('lineBreak') {
            <div class="line-break"></div>
          }
          @case ('field') {
            @switch (item.size) {
              @case ('full') {
                <div class="col-sm-12">
                  <app-universal-form-input
                  [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                  [filledMarker]="true"
                  [formGroupInstance]="formGroup"
                  [parentPath]="parentPath"
                  [showDescription]="item.showDescription"
                ></app-universal-form-input>
                </div>
              }
              @case ('half') {
                <div class="col-sm-6">
                  <app-universal-form-input
                  [field]="controlMap.get(parentPath ? parentPath + '.' + item.name : item.name)"
                  [filledMarker]="true"
                  [formGroupInstance]="formGroup"
                  [parentPath]="parentPath"
                  [showDescription]="item.showDescription"
                ></app-universal-form-input>
                </div>
              }
            }
          }
        }
      }
      </div>
    </div>
  </div>
}
