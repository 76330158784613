import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Feature } from 'ol';
import { WMSCapabilities } from 'ol/format';
import { DrawEvent } from 'ol/interaction/Draw';
import { Observable, map } from 'rxjs';
import { MapService } from 'src/app/services/map-service/map.service';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { MapComponent } from '../../components/map/map.component';

@Component({
  selector: 'app-map-polygon',
  templateUrl: './map-polygon.component.html',
  styleUrls: ['./map-polygon.component.scss'],
  providers: [MapService, { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: MapPolygonControlValueAccessor }],
  standalone: true,
  imports: [MapComponent],
})
export class MapPolygonControlValueAccessor implements OnInit, ControlValueAccessor {
  constructor(
    private mapService: MapService,
    private metarisc: MetariscService,
    private http: HttpClient,
  ) {}

  @Output() emitCoordinates = new EventEmitter<Feature | undefined>();

  feature?: Feature;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (feature: Feature): void => {
    // do nothing
  };
  onTouch?: () => void;

  writeValue(feature: Feature): void {
    this.feature = feature;
  }
  registerOnChange(fn: (feature: Feature) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  @ViewChild('mapGeneral') mapGeneral!: MapComponent;
  ngOnInit(): void {
    this.mapService.drawingInteraction('zoneSelect', { type: 'Polygon', unique: true }).subscribe((event) => {
      this.feature = (event as DrawEvent).feature;
      this.emitCoordinates.emit(this.feature);
      this.onChange(this.feature);
    });
    this.metarisc
      .getMetarisc()
      .organisations?.paginateOrganisationGeoservices('db0e189b-fa4e-4dd1-a424-ac3d7026cbaa')
      .autoPagingIteratorToArray()
      .then((response) => {
        if (response && response.length > 0 && response[0].url)
          return this.listLayerOfCapabilities(response[0].url).pipe(map((layers) => layers));
        else throw new Error('No geoservices found');
      })
      .then((layers) => {
        layers.subscribe((layer) => {
          layer.forEach((layerName) => {
            this.mapService.addWMSLayer(
              'https://srv-gcweb.sdis62.fr/geoconcept-web/wms',
              { LAYERS: layerName },
              layerName,
            );
          });
          this.mapGeneral.refreshLayerList();
        });
      });
  }
  listLayerOfCapabilities(url: string): Observable<string[]> {
    const paramCapabilities = '?REQUEST=GetCapabilities';
    const urlCapabilities = url.includes('GetCapabilities') ? url : url + paramCapabilities;

    return this.http.get(urlCapabilities, { responseType: 'text' }).pipe(
      map((response) => {
        const parser = new WMSCapabilities();
        const capabilities = parser.read(response);
        return capabilities.Capability.Layer.Layer.map((layer: any) => layer.Name);
      }),
    );
  }
}
