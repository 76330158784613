import { Component, Input } from '@angular/core';
import { ERP } from '@metarisc/metarisc-js/src/model/ERP';
import { BadgeErpComponent } from '../../badges/badge-erp/badge-erp.component';
import { DatePipe, NgFor, NgIf } from '@angular/common';
import { BadgeExternalReferenceComponent } from '../../badges/badge-external-reference/badge-external-reference.component';

interface ERPUpdated extends ERP {
  references_exterieures?: object[];
}

@Component({
  selector: 'app-list-erp-item',
  templateUrl: './list-erp-item.component.html',
  styleUrls: ['./list-erp-item.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, BadgeErpComponent, DatePipe, BadgeExternalReferenceComponent],
})
export class ListErpItemComponent {
  @Input() erpList: ERPUpdated[] = [];
}
