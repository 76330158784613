import { Component } from '@angular/core';

@Component({
  selector: 'app-badge-dossier-archive',
  templateUrl: './badge-dossier-archive.component.html',
  styleUrls: ['./badge-dossier-archive.component.css'],
  standalone: true,
  imports: [],
})
export class BadgeDossierArchiveComponent {}
