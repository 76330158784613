import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-universal-form-check',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './universal-form-check.component.html',
  styleUrl: './universal-form-check.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UniversalFormCheckComponent,
      multi: true,
    },
  ],
})
export class UniversalFormCheckComponent implements ControlValueAccessor {
  value: boolean | null = null;

  UniversalFormCheckComponent = UniversalFormCheckComponent;

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  changeValue(value: boolean | null): void {
    if (this.value !== value) {
      this.value = value;
      this.onChange(value);
    }
  }
}
