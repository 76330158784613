<div class="d-flex flex-column h-100 p-2 position-relative">
  <div class="position-absolute text-info d-flex fs-6 ff-regular lh-1 mb-1_5 me-2" style="right: 0">
    <i class="bi bi-info-circle me-1"></i>Maintenez ctrl + click (où cmd + click) pour fusionner plusieurs tracés
  </div>
  <form (ngSubmit)="onSubmitAddress()" class="flex-grow-0">
    <label class="form-label" for="">Rechercher un lieu (une rue, une ville une place) : </label>
    <div class="input-group">
      <input [(ngModel)]="lieu" class="form-control" type="text" />
      <button class="btn btn-outline-primary">Recherche</button>
    </div>
  </form>
  <div class="d-grid flex-grow-1 mt-1 overflow-hidden" style="grid-template-columns: 1fr 2fr">
    <div class="result_list pe-1 h-100 overflow-auto">
      <app-loading [load]="loading">
        <ul class="list-group rounded-0">
          <li
            *ngFor="let feature of lieux; let index = index"
            (click)="selectFeature(index, $event)"
            class="list-group-item cursor-pointer"
            [ngClass]="{ 'bg-info text-white': selectedFeaturesIndex.includes(index) }"
          >
            {{ feature.display_name }}
          </li>
        </ul>
        @if (lieux?.length) {
          <div class="d-flex justify-content-end mt-2">
            <button
              (click)="onSubmitGeoJson()"
              type="button"
              class="btn btn-primary"
              [disabled]="!selectedFeaturesIndex.length"
            >
              Valider
            </button>
          </div>
        } @else {
          <app-no-result></app-no-result>
        }
      </app-loading>
    </div>
    <div><app-map></app-map></div>
  </div>
</div>
