import { NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Feature } from 'ol';
import { GeoJSON } from 'ol/format';
import { MapPolygonControlValueAccessor } from '../map-polygon/map-polygon.component';
import { HttpClient } from '@angular/common/http';
import { MapByLocationControlValueAccessor } from '../map-by-location/map-by-location.component';

type fnOnChange = (_: string) => void;

@Component({
  selector: 'app-form-control-zone',
  templateUrl: './form-control-zone.component.html',
  styleUrls: ['./form-control-zone.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FormControlZoneComponent, multi: true }],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgFor,
    NgIf,
    MapPolygonControlValueAccessor,
    FormsModule,
    MapByLocationControlValueAccessor,
  ],
})
export class FormControlZoneComponent implements ControlValueAccessor {
  http = inject(HttpClient);
  modal = inject(NgbModal);

  modalRef?: NgbModalRef;
  feature?: Feature;

  fluxWfs = new FormControl('');
  zone = new FormControl({ value: '', disabled: true });
  polygon = new FormControl('');

  dataFluxWfs = ['flux 1', 'flux 2', 'flux 3', 'flux 4', 'flux 5', 'flux 6', 'flux 7', 'flux 8', 'flux 9', 'flux 10'];
  dataZone?: Map<string, string> = new Map();

  titleZone: string[] = [];

  lieu: string = '';

  @Output() polygonMapConfirm = new EventEmitter();

  @ViewChild('textLoad') textLoad!: ElementRef<HTMLOptionElement>;

  onChange: fnOnChange = (): void => {
    //do nothing
  };
  onTouch?: () => void;

  writeValue(feature: Feature): void {
    this.feature = feature;
  }
  registerOnChange(fn: fnOnChange): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  @ViewChild('mapByLocation') mapByLocation!: ElementRef;
  submitLieu(): void {
    this.modalRef = this.modal.open(this.mapByLocation, { size: 'xl' });
  }
  onCancelMapByLocation(): void {
    this.modalRef?.close();
  }

  @ViewChild('mapModal') mapModal!: ElementRef;
  openMapPopup(): void {
    this.modalRef = this.modal.open(this.mapModal);
  }

  onMapZoneEmited(feature: Feature | undefined): void {
    this.feature = feature;
  }

  onMapLocationEmited(feature: Feature): void {
    this.feature = feature;
    this.modalRef?.close();
    this.onConfirm();
  }

  cancelManualZone(): void {
    this.onChange('');
    this.feature = undefined;
    if (this.modalRef) this.modalRef.close();
  }

  onConfirm(): void {
    if (this.feature) {
      this.fluxWfs.setValue('');
      this.zone?.setValue('');

      const geoJsonFeature = new GeoJSON().writeFeature(this.feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
        decimals: 6,
      });

      this.onChange(geoJsonFeature);

      //on envoi l'information qu'on a cliqué sur le bouton de confirmation de la modal
      //et pas qu'on a cliqué sur une zone disponible (en select)
      this.polygonMapConfirm.emit();

      this.modalRef?.close();
    }
  }
}
