import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Collection } from '@metarisc/metarisc-js';
import { RechercheService, Univers } from 'src/app/features/recherche/services/recherche-service/recherche.service';
import { MetariscService } from 'src/app/services/metarisc-service/metarisc.service';
import { FiltersControlValueAccessor } from '../../forms/filters/filters.component';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-general-search',
  templateUrl: './general-search.component.html',
  styleUrls: ['./general-search.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgIf, NgFor, FiltersControlValueAccessor],
})
export class GeneralSearchComponent implements OnInit, OnDestroy {
  @Input() univers_list: Univers[] = [];
  @Input() show_univers = true;
  @Input() default_univers?: string;
  @Input() show_search_input = true;

  @Output() changeUnivers = new EventEmitter<Univers>();
  @Output() searchAction = new EventEmitter<Collection<any>>();

  selected_univers?: Univers;
  univers_placeholder = 'Rechercher';

  search_input = '';
  filters_form: FormGroup = this.fb.group({
    filters: [],
  });

  private searchText = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private rechercheService: RechercheService,
    private metariscService: MetariscService,
  ) {}

  get params(): { [param: string]: string | string[] } {
    return this.filters_form.controls['filters'].value;
  }

  @ViewChild('inputSearchElement') inputSearchElement!: ElementRef;

  ngOnInit(): void {
    const query_params = this.route.snapshot.queryParams;
    // Univers
    if (this.univers_list.length) {
      let univers;
      if (this.default_univers) {
        const find_univers = this.univers_list.find((u) => u.endpoint === this.default_univers);
        if (find_univers) {
          univers = find_univers;
        }
      }
      this.selected_univers = univers || this.univers_list[0];
      this.refreshUniversPlaceholder();
      this.onChangeUnivers(false);
    }
    // Input de recherche
    if (this.selected_univers) {
      this.search_input = query_params[this.selected_univers.search_method] || '';
    }
    // Paramètres
    this.filters_form.controls['filters'].patchValue({ ...query_params });
    this.filters_form.updateValueAndValidity();
    // Lancement de la première recherche
    this.search();
    // Initialisation de l'événement sur le changement d'un filtre
    this.filters_form.controls['filters'].valueChanges.subscribe(() => {
      this.search();
      this.rechercheService.updateQueryParams(this.params);
    });
    this.rechercheService.reloadSearch.subscribe((text: string) => {
      this.search_input = text;
      this.inputSearchElement.nativeElement.focus();
      this.search();
    });
    //Handle debouncing of search
    this.searchText.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      this.search();
      if (this.selected_univers?.search_method) this.params[this.selected_univers.search_method] = this.search_input;
      this.rechercheService.updateQueryParams(this.params);
    });
  }

  debounceSearch(text: string): void {
    this.searchText.next(text);
  }

  resetSearch(): void {
    this.searchText.next('');
    this.search();
  }

  getValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  onChangeUnivers(emit = true): void {
    if (this.selected_univers) {
      // Mise à jour du placeholer
      this.refreshUniversPlaceholder();
      // Réinitialisation des filtres
      this.filters_form.controls['filters'].reset();
      this.filters_form.updateValueAndValidity();
      // Événement pour le parent
      if (emit) this.changeUnivers.emit(this.selected_univers);
      // Update de la recherche
      this.search();
    }
  }

  refreshUniversPlaceholder(): void {
    if (this.selected_univers) {
      switch (this.selected_univers.libelle) {
        case 'PEI':
          this.univers_placeholder = 'Rechercher par numéro';
          break;
        case 'ERP':
          this.univers_placeholder = 'Rechercher par nom';
          break;
        default:
          this.univers_placeholder = 'Rechercher';
          break;
      }
    }
  }

  search(): void {
    if (this.selected_univers) {
      const params = { ...this.params };
      if (this.search_input) {
        params[this.selected_univers.search_method] = this.search_input;
      }
      const collection = this.metariscService.getMetarisc().collect({
        method: 'GET',
        endpoint: this.selected_univers.endpoint,
        params: params,
      });
      this.searchAction.emit(collection);
    }
  }

  isSelectedUniverse(libelle: string): boolean {
    return !!this.selected_univers && this.selected_univers.libelle === libelle;
  }

  ngOnDestroy(): void {
    this.searchText.complete();
  }
}
