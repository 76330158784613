<div *ngIf="placeholder || dossier">
  <ng-container *ngIf="placeholder; else display_data">
    @for (iPlaceholder of placeholder | nbToArray; track $index) {
      <div class="dossier d-flex align-items-start gap-2 frosted-glass link p-2 mb-2">
        <div class="status"></div>
        <div class="row flex-grow-1 justify-content-between gx-1_5 gy-1_5">
          <div class="col-lg-8 placeholder-glow">
            <div class="placeholder ff-semi-bold fs-5 w-50"></div>
            <div class="placeholder mt-1_5 w-100"></div>
            <div class="placeholder-glow d-flex flex-wrap gap-2 mt-1_5">
              <span class="placeholder fs-3 w-25"></span>
              <span class="placeholder fs-4"></span>
            </div>
            <div class="placeholder-glow d-flex flex-wrap gap-1 mt-1_5">
              <span class="placeholder w-25"></span>
              <span class="placeholder w-25"></span>
            </div>
          </div>
          <div class="col-lg-4 placeholder-glow">
            <div
              class="d-flex flex-wrap flex-lg-column align-items-center align-items-lg-end text-end gap-1_5 gap-lg-2_5"
            >
              <span class="placeholder w-50 lg-w-75"></span>
              <span class="placeholder fs-5 w-25"></span>
            </div>
          </div>
        </div>
      </div>
    }
  </ng-container>
  <ng-template #display_data>
    <div [routerLink]="getUrl()" class="dossier d-flex align-items-start gap-2 frosted-glass link p-2">
      <div class="status" [ngClass]="{ active: dossier.dossier.statut === 'OUVERT' }"></div>
      <div class="row flex-grow-1 justify-content-between gx-1_5 gy-1_5">
        <div class="col-lg-7">
          <div class="ff-semi-bold fs-5">{{ dossier.dossier.objet || 'Sans objet' }}</div>
          @if (dossier.dossier.est_archive) {
            <div class="d-flex flex-wrap gap-1_5 mt-1_5">
              <app-badge-dossier-archive></app-badge-dossier-archive>
            </div>
          }
          <div *ngIf="pei" class="d-flex flex-wrap gap-1_5 mt-1_5">
            <app-badge-deci-type [data]="pei"></app-badge-deci-type>
          </div>
          <div *ngIf="erp" class="d-flex flex-wrap gap-1_5 mt-1_5">
            <app-badge-erp
              [list]="[
                {
                  id: erp.id || 'noid',
                  type: erp.descriptif_technique?.analyse_risque?.activite_principale?.type || '',
                  name: erp.descriptif_technique?.libelle || 'ERP sans nom',
                },
              ]"
            ></app-badge-erp>
          </div>
          @if (dossier.tags && dossier.tags.length) {
            <div class="d-flex flex-wrap gap-1_5 mt-1_5">
              @for (tag of dossier.tags; track $index) {
                <app-tag *ngIf="tag.message" [tag]="tag.message"></app-tag>
              }
            </div>
          }
        </div>
        <div class="col-lg-3">
          <div
            class="d-flex flex-wrap flex-lg-column align-items-center align-items-lg-end text-end gap-1_5 gap-lg-2_5"
          >
            <span class="text-muted fs-6">
              {{ formatDate() }}
              <span *ngIf="dossier.dossier.createur" class="text-info">
                par
                @if (dossier.dossier.createur.first_name || dossier.dossier.createur.last_name) {
                  {{ dossier.dossier.createur.first_name || '' }} {{ dossier.dossier.createur.last_name || '' }}
                } @else {
                  import automatique
                }
              </span>
            </span>
            <app-via-app
              *ngIf="dossier.dossier.application_utilisee_nom"
              [app]="dossier.dossier.application_utilisee_nom"
            ></app-via-app>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
