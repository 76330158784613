<div class="d-flex flex-column gap-1">
  <div class="frosted-glass p-1" *ngFor="let erp of erpList; let id = index">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="icon d-flex align-items-center justify-content-center"><i class="erp"></i></div>
        <div>
          <div>
            <app-badge-erp
              *ngIf="erp.id"
              [clickable]="true"
              [list]="[
                {
                  id: erp.id,
                  type: erp.descriptif_technique?.analyse_risque?.activite_principale?.type || '',
                  name: erp.descriptif_technique?.libelle || 'ERP sans nom',
                },
              ]"
            ></app-badge-erp>
          </div>
          <div class="mt-1">
            <app-badge-external-reference
              *ngIf="erp.references_exterieures"
              [data]="erp.references_exterieures"
            ></app-badge-external-reference>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <span class="bg-light address">
          {{ erp.implantation?.voie }}
          @if (erp.implantation?.code_postal || erp.implantation?.commune) {
            , {{ erp.implantation?.code_postal }} {{ erp.implantation?.commune }}
          }
        </span>
        <span *ngIf="erp?.implantation?.complement" class="bg-light address">{{ erp.implantation?.complement }}</span>
      </div>
      <div class="d-flex flex-column text-end">
        <span class="date-created"
          >Créé le {{ erp.date_de_realisation | date: 'mediumDate' }}
          <!--par <span class="text-info">{{ 'NOM Prénom' }}</span>-->
        </span>
        <!--<app-via-app [app]="'Plat\'au'"></app-via-app>-->
      </div>
    </div>
  </div>
</div>
