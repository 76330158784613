import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Organisation } from '@metarisc/metarisc-js/lib/model/Organisation';

@Component({
  selector: 'app-badge-organisation',
  templateUrl: './badge-organisation.component.html',
  styleUrls: ['./badge-organisation.component.scss'],
  standalone: true,
  imports: [RouterLink],
})
export class BadgeOrganisationComponent {
  @Input() organisation!: Organisation;
  @Input() role?: string;
}
