<div
  class="btn btn-filter py-1 px-1_5 ff-medium position-relative"
  #customDropdownButton
  data-bs-toggle="dropdown"
  aria-expanded="false"
  data-bs-auto-close="outside"
>
  @if (!isOpen && filterValue() === '...') {
    {{ title }}
    <i class="bi bi-caret-down-fill"></i>
  } @else {
    <p class="border-title" [ngClass]="{ open: isOpen }">{{ title }}</p>
    <div class="d-flex justify-content-between">
      <span>{{ filterValue() }}</span>
      <span class="ms-3 bi" [ngClass]="{ 'bi-caret-up-fill': isOpen, 'bi-caret-down-fill': !isOpen }"></span>
    </div>
  }
  <span
    *ngIf="viewCancelBtn()"
    (click)="deleteField()"
    class="bi bi-x text-danger fs-4 position-absolute"
    style="right: -20px; top: 4px"
  ></span>
</div>

<div class="dropdown-menu p-2" aria-labelledby="dropdown" #dropdownMenu>
  <div class="mb-2">
    <ng-content></ng-content>
  </div>
  <div class="d-flex justify-content-between align-items-center">
    <button class="btn btn-sm ff-medium text-secondary py-0_5 px-1_5" (click)="closeDropdown()">Annuler</button>
    <button class="btn btn-sm btn-primary py-0_5 px-1_5" (click)="emitValue()">Appliquer</button>
  </div>
</div>
