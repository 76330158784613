import { NgClass, NgIf } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
  ViewChild,
  computed,
  input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-generic-filter',
  templateUrl: './generic-filter.component.html',
  styleUrls: ['./generic-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf],
})
export class GenericFilterComponent {
  @Input({ required: true }) title!: string;
  formControlMap = input<Map<string, FormControl>>(new Map());

  filterValue = computed(() => {
    return this.getValue();
  });

  @Output() searchChange = new EventEmitter<Map<string, FormControl>>();

  @ViewChild('customDropdownButton') customDropdownButton?: ElementRef;
  @ViewChild('dropdownMenu') dropdownMenu?: ElementRef<HTMLDivElement>;

  isOpen = false;

  constructor(private renderer: Renderer2) {}

  emitValue(): void {
    this.searchChange.emit(this.formControlMap());
    this.closeDropdown();
  }

  closeDropdown(): void {
    this.isOpen = false;
    this.dropdownMenu?.nativeElement.classList.remove('show');
  }

  @HostListener('show.bs.dropdown', ['$event'])
  onDropdownShow(): void {
    this.renderer.addClass(this.customDropdownButton?.nativeElement, 'dropdown-open');
    this.isOpen = true;
  }

  @HostListener('hidden.bs.dropdown', ['$event'])
  onDropdownHidden(): void {
    this.renderer.removeClass(this.customDropdownButton?.nativeElement, 'dropdown-open');
    this.isOpen = false;
  }

  @HostListener('keydown.enter')
  handleEnterKey(): void {
    if (this.isOpen) {
      this.emitValue();
    }
  }

  getValue(): string {
    let res = '';
    this.formControlMap().forEach((control: FormControl, name) => {
      if (control.value !== undefined && control.value !== '' && control.value !== null) {
        if (name === 'geojson') {
          res = 'Zone manuelle';
        } else if (name === 'date') {
          const firstDate = new Date(control.value.split('/')[0]);
          res = firstDate.getFullYear().toString();
        } else {
          res += res !== '' ? ' / ' + control.value : control.value;
        }
      }
    });
    if (res === 'true') {
      return 'Oui';
    }
    if (res === 'false') {
      return 'Non';
    }
    return res.length > 15 ? res.substring(0, 15) + '...' : res || '...';
  }

  viewCancelBtn(): boolean {
    return Array.from(this.formControlMap()).some((control: [string, FormControl]) => control[1].value !== null);
  }

  deleteField(): void {
    this.formControlMap().forEach((control: FormControl) => {
      control.setValue(null);
    });
    this.emitValue();
  }
}
