<!--<div class="row align-item-center">
  <label class="col-md-4 col-form-label" for="fluxWfs">Flux WFS:</label>
  <div class="col-md-8">
    <select id="fluxWfs" [formControl]="fluxWfs" class="col form-select form-select-sm">
      <option disabled selected value>-- Sélectionnez Flux WFS --</option>
      <option *ngFor="let item of dataFluxWfs" [value]="item">{{ item }}</option>
    </select>
  </div>
</div>
<div class="row align-item-center">
  <label class="col-md-4 col-form-label" for="zone">Zone:</label>
  <div class="col-md-8">
    <select id="zone" [formControl]="zone" class="col form-select form-select-sm">
      <option #textLoad disabled selected value="">-- Sélectionnez Zone --</option>
      <option *ngFor="let item of titleZone" [value]="item">{{ item }}</option>
    </select>
  </div>
</div>-->
<form (ngSubmit)="submitLieu()" class="form-group">
  <label class="form-label" for="lieu">Nom de lieu (rue, ville, quartier) :</label>
  <div class="input-group">
    <input class="form-control" name="lieu" id="lieu" [(ngModel)]="lieu" type="text" />
    <button class="btn btn-outline-primary">Chercher</button>
  </div>
</form>
<div class="text-center mb-1">Ou</div>
<div class="text-center text-primary">
  <button class="btn btn-outline-primary" (click)="openMapPopup()">
    {{ !feature ? 'Selectionnez sur la carte' : 'Modifier la selection' }}
  </button>
  <button class="btn btn-close ms-2" (click)="cancelManualZone()" *ngIf="feature"></button>

  <ng-template #mapModal>
    <div style="height: 500px">
      <app-map-polygon (emitCoordinates)="onMapZoneEmited($event)"></app-map-polygon>
    </div>
    <div class="footer d-flex justify-content-between gap-2">
      <button type="button" class="btn btn-outline-danger" (click)="cancelManualZone()">Annuler</button>
      <button type="button" class="btn btn-success" (click)="onConfirm()">Valider</button>
    </div>
    <button type="button" class="btn btn-cancel-modal" (click)="cancelManualZone()">
      <i class="bi bi-x-lg"></i>
    </button>
  </ng-template>
  <ng-template #mapByLocation>
    <div class="d-flex flex-column" style="height: calc(100vh - 120px)">
      <button type="button" class="btn btn-cancel-modal flex-grow-0 z-1" (click)="onCancelMapByLocation()">
        <i class="bi bi-x-lg"></i>
      </button>
      <app-map-by-location
        (emitCoordinates)="onMapLocationEmited($event)"
        [inputAdresse]="lieu"
        class="h-100"
      ></app-map-by-location>
    </div>
  </ng-template>
</div>
