@if (field) {
  <div class="d-flex">
    @if (filledMarker) {
      <div class="filled-indicator">
        @if (hasValue(field.control)) {
          <i class="bi bi-check-circle"></i>
        }
      </div>
    }
    <div class="row mb-1 flex-grow-1">
      <div class="col-sm-12">
        @switch (field.type) {
          @case (ControlType.text) {
            <label
              class="form-label"
              [class.is-filled]="filledMarker && hasValue(field.control)"
              [for]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
            >
              {{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            @if (field.maxLength && field.maxLength > 255) {
              <textarea
                [id]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
                class="form-control"
                [formControl]="$any(field.control)"
              ></textarea>
            } @else {
              <input
                [id]="index !== undefined ? field.fullKey + '_' + index : field.fullKey"
                class="form-control"
                [formControl]="$any(field.control)"
                type="text"
              />
            }
            <small
              [id]="index !== undefined ? field.fullKey + '_' + index + '_description' : field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
          }
          @case (ControlType.number) {
            <label class="form-label" [class.is-filled]="filledMarker && hasValue(field.control)" [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <input
              [id]="field.fullKey"
              class="form-control"
              [formControl]="$any(field.control)"
              type="number"
              [placeholder]="field.placeholder"
            />
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
          }
          @case (ControlType.checkbox) {
            <div>
              <label
                class="form-label"
                [class.is-filled]="filledMarker && hasValue(field.control)"
                [for]="field.fullKey"
                >{{ getPrettyText(field.label) }}
                @if (isRequired(field.control)) {
                  <span class="required-field">*</span>
                }
              </label>
              <app-universal-form-check [formControl]="$any(field.control)"></app-universal-form-check>
              <small
                [id]="field.fullKey + '_description'"
                class="form-text text-muted d-block"
                [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
              ></small>
            </div>
          }
          @case (ControlType.date) {
            <label class="form-label" [class.is-filled]="filledMarker && hasValue(field.control)" [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <input
              [id]="field.fullKey"
              class="form-control"
              [formControl]="$any(field.control)"
              type="date"
              [placeholder]="field.placeholder"
            />
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
          }
          @case (ControlType.select) {
            <label class="form-label" [class.is-filled]="filledMarker && hasValue(field.control)" [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <select [id]="field.fullKey" class="form-select" [formControl]="$any(field.control)">
              <option [ngValue]="null" [disabled]="isRequired(field.control)" selected>{{ field.placeholder }}</option>
              @for (option of field.options; track option) {
                <option [ngValue]="option">{{ getPrettyValue(option) }}</option>
              }
            </select>
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted d-block"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
          }
          @case (ControlType.array) {
            <label
              class="form-label-array"
              [class.is-filled]="filledMarker && hasValue(field.control)"
              [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
            <div class="d-flex flex-column gap-1">
              @for (item of $any(field.control).controls; track item; let i = $index) {
                @if (isFormGroup(item)) {
                  <div class="row row-attribute" @itemAnimation>
                    <div class="attributes-container">
                      <!-- On essaie d'abord de balayer un éventuel layout -->
                      @for (layoutItem of getLayout(field.fullKey); track layoutItem; let j = $index) {
                        <div
                          class="attribute"
                          [class.full-width]="
                            getControlInfo(field.itemsControlInfo, layoutItem.name, item.controls[layoutItem.name])
                              ?.type === ControlType.object ||
                            getControlInfo(field.itemsControlInfo, layoutItem.name, item.controls[layoutItem.name])
                              ?.type === ControlType.array
                          "
                        >
                          <ng-container
                            *ngComponentOutlet="
                              UniversalFormInputComponent;
                              inputs: {
                                field: getControlInfo(
                                  field.itemsControlInfo,
                                  layoutItem.name,
                                  item.controls[layoutItem.name]
                                ),
                                index: j,
                                formGroupInstance: formGroupInstance,
                                parentPath: parentPath,
                                filledMarker: filledMarker,
                              }
                            "
                          ></ng-container>
                        </div>
                      }
                      <!-- Fallback dans le cas ou le layout n'est pas défini -->
                      @if (getLayout(field.fullKey).length === 0) {
                        @for (attribute of item.controls | keyvalue; track attribute.key; let j = $index) {
                          <div
                            class="attribute"
                            [class.full-width]="
                              getControlInfo(field.itemsControlInfo, attribute.key, attribute.value)?.type ===
                                ControlType.object ||
                              getControlInfo(field.itemsControlInfo, attribute.key, attribute.value)?.type ===
                                ControlType.array
                            "
                          >
                            <ng-container
                              *ngComponentOutlet="
                                UniversalFormInputComponent;
                                inputs: {
                                  field: getControlInfo(field.itemsControlInfo, attribute.key, attribute.value),
                                  index: i,
                                  formGroupInstance: formGroupInstance,
                                  parentPath: parentPath,
                                  filledMarker: filledMarker,
                                }
                              "
                            ></ng-container>
                          </div>
                        }
                      }
                    </div>
                    <div class="action" (click)="removeItem($any(field.control), i)">
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                } @else {
                  <div class="row row-attribute">
                    <div class="attributes-container">
                      @if (field.options) {
                        <select [formControl]="item" [id]="field.fullKey" class="form-select">
                          <option [ngValue]="null" [disabled]="isRequired(field.control)" selected>
                            {{ field.placeholder }}
                          </option>

                          @for (option of field.options; track option) {
                            <option [value]="option">{{ getPrettyValue(option) }}</option>
                          }
                        </select>
                      }
                    </div>
                    <div class="action" (click)="removeItem($any(field.control), i)">
                      <i class="bi bi-trash"></i>
                    </div>
                  </div>
                }
              }
            </div>
            <div class="d-flex justify-content-end mt-1">
              <button class="btn btn-primary" type="button" (click)="addItem()">
                + {{ getPrettyText('ajouter_' + field.key) }}
              </button>
            </div>
          }
          @case (ControlType.object) {
            <label
              class="form-label-array"
              [class.is-filled]="filledMarker && hasValue(field.control)"
              [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
            @if (hasValue(field.control)) {
              <div class="row-attribute" @itemAnimation>
                <div class="attributes-container">
                  <!-- On essaie d'abord de balayer un éventuel layout -->
                  @for (layoutItem of getLayout(field.fullKey); track layoutItem; let j = $index) {
                    <div
                      class="attribute"
                      [class.full-width]="
                        getControlInfo(
                          field.itemsControlInfo,
                          layoutItem.name,
                          $any(field.control).controls[layoutItem.name]
                        )?.type === ControlType.object ||
                        getControlInfo(
                          field.itemsControlInfo,
                          layoutItem.name,
                          $any(field.control).controls[layoutItem.name]
                        )?.type === ControlType.array
                      "
                    >
                      <ng-container
                        *ngComponentOutlet="
                          UniversalFormInputComponent;
                          inputs: {
                            field: getControlInfo(
                              field.itemsControlInfo,
                              layoutItem.name,
                              $any(field.control).controls[layoutItem.name]
                            ),
                            index: j,
                            formGroupInstance: formGroupInstance,
                            parentPath: parentPath,
                            filledMarker: filledMarker,
                          }
                        "
                      ></ng-container>
                    </div>
                  }
                  <!-- Fallback dans le cas ou le layout n'est pas défini -->
                  @if (getLayout(field.fullKey).length === 0) {
                    @for (control of $any(field.control).controls | keyvalue; track control.key; let i = $index) {
                      <div class="attribute">
                        <ng-container
                          *ngComponentOutlet="
                            UniversalFormInputComponent;
                            inputs: {
                              field: getControlInfo(field.itemsControlInfo, $any(control.key), $any(control.value)),
                              index: 0,
                              filledMarker: filledMarker,
                              formGroupInstance: formGroupInstance,
                              parentPath: parentPath,
                            }
                          "
                        ></ng-container>
                      </div>
                    }
                  }
                </div>
                <div class="action" (click)="removeObject()">
                  <i class="bi bi-trash"></i>
                </div>
              </div>
            } @else {
              <div class="d-flex justify-content-end mt-1">
                <button class="btn btn-primary" type="button" (click)="addObject()">
                  + {{ getPrettyText('ajouter_' + field.key) }}
                </button>
              </div>
            }
          }
          @case (ControlType.placeholder) {
            <label
              class="form-label-placeholder"
              [class.is-filled]="filledMarker && hasValue(field.control)"
              [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
            @for (child of field.childs; track child.key) {
              <app-universal-form-input [field]="child" [parentPath]="parentPath"></app-universal-form-input>
            }
          }
          @case (ControlType.keyvalue) {
            <label
              class="form-label-placeholder"
              [class.is-filled]="filledMarker && hasValue(field.control)"
              [for]="field.fullKey"
              >{{ getPrettyText(field.label) }}
              @if (isRequired(field.control)) {
                <span class="required-field">*</span>
              }
            </label>
            <small
              [id]="field.fullKey + '_description'"
              class="form-text text-muted"
              [innerHTML]="showDescription ? processFieldDescription(field.description ?? '') : '<p></p>'"
            ></small>
            @for (item of $any(field.control).controls | keyvalue; track item; let i = $index) {
              @if (i === 0) {
                <div class="row mt-3">
                  <div class="col-md-5">Clé</div>
                  <div class="col-md-5">Valeur</div>
                </div>
                <hr />
              }
              <div class="row">
                <div class="col-md-5">
                  {{ item.key }}
                </div>
                <div class="col-md-5">
                  {{ $any(item.value).value }}
                </div>
              </div>
            }

            @if (currentKeyValueFormGroup) {
              <hr />
              <div class="row">
                <div class="col-md-5">
                  <label class="form-label" [class.is-filled]="filledMarker && hasValue(field.control)" for="key">
                    Clé
                    <span class="required-field">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="key"
                    [formControl]="$any(currentKeyValueFormGroup.get('key'))"
                  />
                </div>
                <div class="col-md-5">
                  <label class="form-label" [class.is-filled]="filledMarker && hasValue(field.control)" for="value">
                    Valeur
                    <span class="required-field">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="value"
                    [formControl]="$any(currentKeyValueFormGroup.get('value'))"
                  />
                </div>
                <div class="col-md-1 d-flex justify-content-end align-items-start mini-btn">
                  <button type="button" class="btn btn-sm btn-outline-danger square-btn" (click)="removeKeyValue()">
                    <i class="bi bi-trash"></i>
                  </button>
                </div>
                <div class="col-md-1 d-flex justify-content-end align-items-start mini-btn">
                  <button type="button" class="btn btn-sm btn-success square-btn" (click)="saveKeyValue()">
                    <i class="bi bi-check"></i>
                  </button>
                </div>
              </div>
            }
            @if (!currentKeyValueFormGroup) {
              <div class="d-flex justify-content-end mt-1">
                <button class="btn btn-primary" type="button" (click)="addKeyValue()">+ Ajouter une valeur</button>
              </div>
            }
          }
          @default {
            ERROR NOT SUPPORTED: {{ field.type }}
          }
        }

        @if (field.control && field.control.invalid && (field.control.dirty || field.control.touched)) {
          <div class="error-message">
            {{ getError(field.control) }}
          </div>
        }
      </div>
    </div>
  </div>
}
